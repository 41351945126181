import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "react-icons-kit"
import { close } from "react-icons-kit/fa/close"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Navigation } from "swiper"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Video from "../components/video-tw"
import Carousel from "../components/carousel"
import Footer from "../components/Footer.pt-br"

import "swiper/css"
import "swiper/css/pagination"
import "../css/swiper-hack.css"

SwiperCore.use([Navigation])

const IndexPage = ({ location, data }) => {
  const swiperRef = useRef(null)

  const [overlayOpen, setOverlayOpen] = useState(false)
  const [videoID, setVideoID] = useState("")

  const [windowWidth, setWindowWidth] = useState(
    typeof document !== "undefined" ? document.documentElement.clientWidth : 0
  )
  const setWindow = () => {
    if (typeof document !== "undefined") {
      setWindowWidth(document.documentElement.clientWidth)
    }
  }

  useEffect(() => {
    setWindow()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setWindow)
    }
  }, [])

  const openVideo = id => {
    setVideoID(id)
    setOverlayOpen(true)
  }

  const closeVideo = () => {
    setOverlayOpen(false)
    setVideoID("")
  }

  const getVideoThumbs = (vIds, title) => {
    return (
      <div className="grid grid-cols-1 ">
        {vIds.map((id, i) => {
          return (
            <button onClick={evt => openVideo(id)} key={i}>
              <div className="relative text-center w-fit mx-auto">
                <img
                  src={`https://img.youtube.com/vi/${id}/maxresdefault.jpg`}
                  alt={title}
                  className="max-w-md"
                />
                <GatsbyImage
                  image={getImage(data.videoIcon)}
                  backgroundColor={false}
                  alt="Multilógica"
                  className="bottom-3 left-3"
                  style={{ position: "absolute" }}
                />
              </div>
            </button>
          )
        })}
      </div>
    )
  }

  const makeCarousel = carouselData => {
    console.log(carouselData)
    let carouselImages = []
    carouselData.edges.forEach(element => {
      carouselImages.push({ img: getImage(element["node"]) })
    })
    return (
      <div className="mx-0 md:mx-12">
        <div className="grid grid-cols-12">
          <div className="col-span-1 place-self-center">
            <button onClick={() => swiperRef.current.swiper.slidePrev()}>
              <div className="slider-left" />
            </button>
          </div>
          <div className="col-span-10">
            <Swiper
              slidesPerView={windowWidth >= 768 ? 3 : 1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
                el: ".swiper-portfolio",
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              ref={swiperRef}
            >
              {carouselImages.map((image, k) => {
                return (
                  <SwiperSlide key={k}>
                    <GatsbyImage
                      image={image.img}
                      backgroundColor={false}
                      alt="padrões de desenho"
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <div className="col-span-1 place-self-center">
            <button onClick={() => swiperRef.current.swiper.slideNext()}>
              <div className="slider-right" />
            </button>
          </div>
        </div>
        <div className="swiper-pagination swiper-portfolio" />
      </div>
    )
  }

  return (
    <>
      <Metatags
        title="Multilógica | Serviços"
        description="Fornecedor de maquinário para colchões"
        url="https://newsite.multilogica.ind.br/pt-br/servicos"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchão, máquina de costura, mammut, beckmann sew"
        language="pt-br"
      />
      <div
        className={`${
          overlayOpen ? "block" : "hidden"
        } fixed h-screen bg-slate-500 z-40 w-full`}
      >
        <div className="flex justify-end items-start text-white m-1">
          <button onClick={evt => closeVideo()}>
            <Icon size={32} icon={close} />
          </button>
        </div>

        <div id="video-overlay" className=" flex justify-center items-center">
          {videoID !== "" ? (
            <Video
              viddeoUrl={`https://www.youtube.com/embed/${videoID}`}
              endAction={closeVideo}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <NavBar location={location["pathname"]} />
      <div className="w-full">
        <GatsbyImage
          image={getImage(data.bannerServices)}
          backgroundColor={false}
          alt="máquinas para colchões"
        />
      </div>
      <div className="bg-mi-blue">
        <div className="container mx-auto">
          <div className="h-20" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-white text-4xl font-semibold m-4">
              Manutenção e reparo
            </div>
          </div>
          <div className="h-20" />
        </div>
      </div>
      <div className="h-0 md:h-8" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 place-self-center mr-12">
            <div className="text-mi-blue text-xl m-4">
              Com inspeções e manutenções regulares, a produtividade da máquina se mantém e a vida útil é ampliada.
            </div>
          </div>
          <div className="col-span-1">
            <GatsbyImage
              image={getImage(data.maintenance1)}
              backgroundColor={false}
              alt="manutanção de máquinas"
            />
          </div>
        </div>
        <div className="h-0 md:h-8" />
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 place-self-center mr-12">
            <div className="text-mi-blue text-xl m-4">
              Somos treinados e habilitados para executar os serviços de
              manutenção corretiva, preventiva e preditiva, para que seu
              equipamento esteja sempre em condições de suprir demandas máximas
              reduzindo o tempo de inatividade da máquina.
            </div>
          </div>
          <div className="col-span-1">
            <GatsbyImage
              image={getImage(data.maintenance2)}
              backgroundColor={false}
              alt="manutanção de máquinas"
            />
          </div>
        </div>
        <div className="h-0 md:h-8" />
      </div>
      <div className="bg-mi-blue">
        <div className="container mx-auto">
          <div className="h-20" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-white text-4xl font-semibold m-4">
              Desenvolvimento de desenhos
            </div>
          </div>
          <div className="h-20" />
        </div>
      </div>
      <div className="h-0 md:h-8" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 place-self-center mr-12">
            <div className="text-mi-blue text-xl m-4">
              Oferecemos serviços para clientes que necessitam criar padrões
              exclusivos de desenhos para acolchoados ou até mesmo editar,
              corrigir e adequar desenhos já existentes, bem como importar
              desenhos de outras máquinas.
            </div>
          </div>
          <div className="col-span-1">
            <GatsbyImage
              image={getImage(data.patterns)}
              backgroundColor={false}
              alt="desenvolvimento de padrões de desenho"
            />
          </div>
        </div>
        <div className="h-0 md:h-8" />
      </div>
      <div className="bg-mi-blue">
        <div className="mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-2">
            <div className="col-span-6 m-6 place-self-center">
              <div
                className="text-white text-4xl font-semibold"
                itemProp="headline"
              >
                Personalização
              </div>
              <div className="h-2" />
              <div className="text-white text-xl">
                Desenhos e padrões acolchoados exclusivos.
              </div>
            </div>
            <div
              className="col-span-6 mb-6 mt-10 mx-auto "
              style={{ maxWidth: "768px", width: "-webkit-fill-available" }}
            >
              <Carousel images={data.mattressImages.edges} language="en" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-mi-gray">
        <div className="container mx-auto">
          <div className="h-20" />
          <div className="mx-auto text-center">
            <div className="text-mi-blue text-4xl font-semibold m-4">
              Veja alguns exemplos de nosso portifólio:
            </div>
          </div>
          <div className="h-0 md:h-8" />
          {makeCarousel(data.patternsCarousel)}
          <div className="h-20" />
        </div>
      </div>
      <div className="container mx-auto">
        <div className="h-20" />
        <div>{getVideoThumbs(["v8EW1ot5v4M"], "pattern dedvelopment")}</div>
        <div className="h-20" />
      </div>
      <Footer />
    </>
  )
}
export default IndexPage

export const indexPT = graphql`
  query {
    bannerServices: file(
      relativePath: { eq: "banners/Banner_Servicos_2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
    maintenance1: file(relativePath: { eq: "services/Servicos_Img_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 960)
      }
    }
    maintenance2: file(relativePath: { eq: "services/Servicos_Img_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 960)
      }
    }
    patterns: file(relativePath: { eq: "services/Servicos_Img_3.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 960)
      }
    }

    patternsCarousel: allFile(
      filter: { relativePath: { regex: "/services/patterns/" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 960)
          }
        }
      }
    }
    videoIcon: file(relativePath: { eq: "player-button.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 50)
      }
    }
    mattressImages: allFile(
      filter: { relativePath: { regex: "/mattress-pattern/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
